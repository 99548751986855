<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->
							<article class="article-body" id="article-body" rel="image-enlarge">
								<p>
									In both {{ $store.state.brandInfo.appName }} and {{ $store.state.brandInfo.displayName }} app, there's ability to switch the player type, for
									both VOD and LiveTV.
								</p>
								<p><br /></p>
								<p>
									This can help when experiencing audio or video issues such as no sound issue, sound out of sync, background noise only, video not starting, or
									no video and hearing only sound.
								</p>
								<p><br /></p>
								<p>Some devices run in to some issues when using a particular player, thus the ability to change between players.</p>
								<p>Usually this helps when one experience issues with video and audio out of sync.</p>
								<p><br /></p>
								<p><br /></p>
								<p>TV:</p>
								<p>Click on Profile at the top left:</p>
								<p>
									<img
										src="../../../assets/images/mini-icon/in-theaters.png"
										style="width: 409px"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48048779169"
									/>
								</p>
								<p>Then change the Player Type based on the content you want to switch (LiveTV or VOD)</p>
								<p>
									<img
										src="../../../assets/images/mini-icon/profile-settings.png"
										style="width: 626px"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48048779224"
									/>
								</p>
								<p><br /></p>
								<p>Mobile: you can see it under "More" setting at the bottom part:</p>
								<p><br /></p>
								<p>
									<img
										src="../../../assets/images/mini-icon/profile-settings-m.png"
										style="width: 245px"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48048778696"
									/>
								</p>
								<p><br /></p>
								<p>These are the default, but if you run to any issue, you can always change it.</p>
								<p><br /></p>
								<p><br /></p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ChangingVodLivetvPlayerType',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
